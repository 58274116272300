import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";

export const AcreditacionesModal = ({ isOpen, setIsOpen, acreditaciones }) => {
  const [filterText, setFilterText] = useState("");

  const handleClose = () => {
    setIsOpen(false);
    setFilterText("");
  };

  const columns = [
    {
      field: "code",
      headerName: "Curso",
      width: 150,
      renderCell: (params) => <strong>{params.value}</strong>,
    },
    {
      field: "validFrom",
      headerName: "Válido Desde",
      width: 150,
    },
    {
      field: "validUntil",
      headerName: "Válido Hasta",
      width: 150,
    },
    {
      field: "facilityType",
      headerName: "Tipo",
      width: 200,
    },
  ];

  const filteredRows = acreditaciones
    .filter((acreditacion) => {
      const searchStr = filterText.toLowerCase();
      return acreditacion.code?.toLowerCase().includes(searchStr);
    })
    .map((acreditacion, index) => ({
      id: index,
      ...acreditacion,
    }));

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Acreditaciones</DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 2, mt: 1 }}>
          <TextField
            fullWidth
            label="Filtrar acreditaciones"
            variant="outlined"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            size="small"
          />
        </Box>
        <DataGrid
          rows={filteredRows}
          columns={columns}
          autoHeight
          pageSize={8}
          rowsPerPageOptions={[8]}
          disableSelectionOnClick
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
};
