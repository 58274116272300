import React, { useState, useEffect } from "react";
import { defer } from "rxjs";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { NavBar } from "../../components/NavBar";
import CentroModal from "./CentroModal";
import { getCentrosList } from "../../services/centros.service";
import HeaderActions from "../../components/Header-actions";
import { CheckSquare, XSquare } from "phosphor-react";
import { AcreditacionesModal } from "./AcreditacionesModal";

const Centros = () => {
  const [centrosList, setCentrosList] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [centroSelected, setCentroSelected] = useState({});
  const handleModal = (cell) => {
    setCentroSelected(cell.row);
    setIsModalOpen(true);
  };

  const [isAcreditacionesModalOpen, setIsAcreditacionesModalOpen] =
    useState(false);
  const [selectedAcreditaciones, setSelectedAcreditaciones] = useState([]);

  const handleAcreditacionesClick = (centro) => {
    setSelectedAcreditaciones(centro.acreditaciones || []);
    setIsAcreditacionesModalOpen(true);
  };

  useEffect(() => {
    const subscription = defer(() => getCentrosList()).subscribe((list) => {
      setCentrosList(list);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const localRefresh = async (updatedCentro) => {
    setLoading(true);
    const idxToRefresh = centrosList.findIndex(
      (c) => c.id === updatedCentro.id
    );
    let updatedList = [];
    if (idxToRefresh > -1) {
      updatedList = [
        ...centrosList.slice(0, idxToRefresh),
        updatedCentro,
        ...centrosList.slice(idxToRefresh + 1),
      ];
    } else {
      updatedList = [...centrosList, updatedCentro];
    }
    setCentrosList(updatedList);
    setLoading(false);
  };

  const fullRefresh = () => {
    setLoading(true);
    getCentrosList().then((list) => {
      setCentrosList(list);
      setLoading(false);
    });
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
    },
    {
      field: "codigo",
      headerName: "Codigo",
      width: 160,
      renderCell: (params) => {
        return <strong>{params.row.codigo}</strong>;
      },
    },
    {
      field: "nombre_completo",
      headerName: "Nombre Centro",
      width: 320,
    },
    {
      field: "activo",
      headerName: "Activo",
      renderCell: (params) => {
        return !!params.row.activo ? (
          <CheckSquare size={24} color="#0e9524" />
        ) : (
          <XSquare size={24} color="#ea1f1f" />
        );
      },
    },
    {
      field: "pais",
      headerName: "Pais",
      width: 180,
    },
    {
      field: "logo_url",
      headerName: "Logo",
      description: "Nombre del archivo imagen",
    },
    {
      field: "acreditaciones",
      headerName: "Acreditaciones",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="text"
          onClick={(e) => {
            e.stopPropagation();
            handleAcreditacionesClick(params.row);
          }}
          sx={{ textTransform: "none" }}
        >
          Ver Acreditaciones
        </Button>
      ),
    },
  ];

  return (
    <Box sx={{ display: "flex", mt: 4, mb: 4 }}>
      <NavBar selected="Centros" />
      <Container maxWidth="lg">
        <Header title="Centros" subtitle="Listado de Centros" />
        <HeaderActions
          addItem={() => {
            setCentroSelected({});
            setIsModalOpen(true);
          }}
          addItemText="Agregar Centro"
          refresh={fullRefresh}
        />
        <CentroModal
          centro={centroSelected}
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          refresh={localRefresh}
        />
        <AcreditacionesModal
          isOpen={isAcreditacionesModalOpen}
          setIsOpen={setIsAcreditacionesModalOpen}
          acreditaciones={selectedAcreditaciones}
        />
        <DataGrid
          rows={centrosList}
          columns={columns}
          sx={{ width: "100%", height: 600 }}
          loading={isLoading}
          autoHeight
          pageSize={15}
          rowsPerPageOptions={[15]}
          onCellClick={handleModal}
        />
      </Container>
    </Box>
  );
};

export default Centros;
